<template>
  <div class="main-container">
    <div class="nav">
      <el-breadcrumb separator="/" class="item">
        <el-breadcrumb-item :to="{ name: 'MemberErrorList' }">错题记录</el-breadcrumb-item>
        <el-breadcrumb-item>巩固错题</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content" v-if="questions.length">
      <div class="left">
        <div class="question">
          <div class="stem">
            <div class="num-type">
              <span class="number">{{curQuesIndex + 1}}.</span>
              <span class="type">({{ExamTopicType.getValue(questions[curQuesIndex].ques_type)}})</span>
            </div>
            <div class="rich-text" v-html="questions[curQuesIndex].ques_stem"></div>
          </div>
          <div class="option-wrapper">
            <img
              class="result-tag"
              v-show="questions[curQuesIndex].result !== undefined"
              :src="questions[curQuesIndex].result ? rightTag : wrongTag"
            />
            <div
              class="single"
              v-if="questions[curQuesIndex].ques_type == 1 || questions[curQuesIndex].ques_type == 3"
            >
              <el-radio-group
                class="options"
                v-model="questions[curQuesIndex].single"
                @change="onCompleteQues()"
                :disabled="questions[curQuesIndex].result !== undefined"
              >
                <el-radio
                  class="option"
                  v-for="(item, index) in questions[curQuesIndex].ques_option"
                  :key="index"
                  :label="item.id"
                >
                  <span>{{$pub.getLetterNumber(item.id)}}、</span>
                  <div class="option-content" v-html="item.content"></div>
                </el-radio>
              </el-radio-group>
            </div>
            <div class="multi" v-if="questions[curQuesIndex].ques_type == 2">
              <el-checkbox-group
                class="options"
                v-model="questions[curQuesIndex].multi"
                :disabled="questions[curQuesIndex].result !== undefined"
              >
                <el-checkbox
                  class="option"
                  v-for="(item, index) in questions[curQuesIndex].ques_option"
                  :key="index"
                  :label="item.id"
                >
                  <span>{{$pub.getLetterNumber(item.id)}}、</span>
                  <div class="option-content" v-html="item.content"></div>
                </el-checkbox>
              </el-checkbox-group>
              <div class="btn-ok" v-show="questions[curQuesIndex].result === undefined">
                <el-button type="primary" size="medium" @click="onCompleteQues()">确定</el-button>
              </div>
            </div>
            <div v-if="questions[curQuesIndex].ques_type == 4">
              <div class="connection">
                <div class="part-ques">
                  <p class="part-title">题目</p>
                  <div
                    class="ques"
                    v-for="(item, index) in questions[curQuesIndex].ques_option.left"
                    :key="index"
                  >
                    <div class="stem">
                      <span class="stem-number">{{index + 1}})</span>
                      <div class="stem-text" v-html="item.content"></div>
                    </div>
                    <div class="answer">
                      <span class="tag">答案</span>
                      <el-radio-group
                        v-model="questions[curQuesIndex].connection[index]"
                        :disabled="questions[curQuesIndex].result !== undefined"
                      >
                        <el-radio
                          v-for="(item, index) in questions[curQuesIndex].ques_option.right"
                          :key="index"
                          :label="item.id"
                        >{{$pub.getLetterNumber(item.id)}}</el-radio>
                      </el-radio-group>
                    </div>
                  </div>
                </div>
                <div class="part-option">
                  <p class="part-title">选项</p>
                  <div
                    class="option"
                    v-for="(item, index) in questions[curQuesIndex].ques_option.right"
                    :key="index"
                  >
                    <div class="option-number">选项{{$pub.getLetterNumber(item.id)}}</div>
                    <div class="option-content" v-html="item.content"></div>
                  </div>
                </div>
              </div>
              <div class="btn-ok" v-show="questions[curQuesIndex].result === undefined">
                <el-button type="primary" size="medium" @click="onCompleteQues()">确定</el-button>
              </div>
            </div>
          </div>
          <div class="watch-answer" v-show="showAnswer">
            <div class="bar">
              <span class="right">
                <span>正确答案：</span>
                <span class="tag">{{getCurDisplay('answer')}}</span>
              </span>
              <span class="choice">
                <span>您的答案：</span>
                <span class="tag">{{getCurDisplay('choice')}}</span>
              </span>
            </div>
            <div class="point">
              <span class="title">考点：</span>
              <span
                class="tag"
                v-for="(item, index) in questions[curQuesIndex].points"
                :key="index"
              >{{item}}</span>
              <span class="empty-tag" v-if="!questions[curQuesIndex].points">暂无考点</span>
            </div>
            <div class="analysis">
              <span class="title">题目解析：</span>
              <div class="rich-text" v-html="questions[curQuesIndex].ques_analysis"></div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <p @click="onCollect">
            <img
              v-show="questions[curQuesIndex].collect_id"
              src="~@/assets/images/icon-collect-active@3x.png"
              alt
            />
            <img
              v-show="!questions[curQuesIndex].collect_id"
              src="~@/assets/images/icon-collect@3x.png"
              alt
            />
            <span :class="{active: questions[curQuesIndex].collect_id}">收藏</span>
          </p>
          <p @click="onCorrection">
            <img src="~@/assets/images/icon-stop@3x.png" alt />
            <span>纠错</span>
          </p>
          <p @click="onAnswer">
            <img v-show="showAnswer" src="~@/assets/images/icon-answer-active@3x.png" alt />
            <img v-show="!showAnswer" src="~@/assets/images/icon-answer@3x.png" alt />
            <span :class="{active: showAnswer}">查看答案</span>
          </p>
          <p @click="onDelete">
            <img src="~@/assets/images/icon-delete@3x.png" alt />
            <span>移除错题</span>
          </p>
        </div>
      </div>
      <div class="right">
        <div class="btn">
          <el-button @click="onPreQues">上一题</el-button>
          <el-button @click="onNextQues">下一题</el-button>
        </div>
        <div class="board">
          <div class="board-title">
            <span class="tag">答题卡</span>
            <span class="current">{{completeQuesCount}}</span>
            <span>/{{questions.length}}</span>
          </div>
          <div class="board-content">
            <p
              class="item"
              :class="{right: item.result === true, wrong: item.result === false, select: item.result === undefined && index == curQuesIndex}"
              v-for="(item, index) in questions"
              :key="index"
              @click="curQuesIndex = index; showAnswer = false;"
            >{{index + 1}}</p>
          </div>
        </div>
        <div class="tip">
          <p class="tip-right">
            <i></i>
            <span>正确{{rightQuesCount}}</span>
          </p>
          <p class="tip-wrong">
            <i></i>
            <span>错误{{wrongQuesCount}}</span>
          </p>
          <p class="tip-incomplete">
            <i></i>
            <span>未做{{questions.length - completeQuesCount}}</span>
          </p>
        </div>
        <el-button class="btn-over" type="primary" @click="onOver">结束巩固</el-button>
      </div>
    </div>
    <CorrectionDlg
      v-if="questions.length"
      v-model="showCorrectionDlg"
      :quesID="questions[curQuesIndex].ques_id"
    ></CorrectionDlg>
  </div>
</template>

<script>
import { ExamTopicType } from "@/utils/enums";
import CorrectionDlg from "@/components/CorrectionDlg";
export default {
  name: "consolidate",
  components: {
    CorrectionDlg
  },
  data() {
    return {
      ExamTopicType,
      paperID: this.$route.query.paper_id,
      createdAt: this.$route.query.created_at,
      curQuesIndex: 0,
      questions: [],
      rightTag: require("@/assets/images/icon-right@3x.png"),
      wrongTag: require("@/assets/images/icon-wrong@3x.png"),
      showAnswer: false,
      showCorrectionDlg: false
    };
  },
  computed: {
    completeQuesCount() {
      let ret = 0;
      this.questions.forEach(i => {
        if (i.result !== undefined) {
          ++ret;
        }
      });
      return ret;
    },
    rightQuesCount() {
      let ret = 0;
      this.questions.forEach(i => {
        if (i.result === true) {
          ++ret;
        }
      });
      return ret;
    },
    wrongQuesCount() {
      let ret = 0;
      this.questions.forEach(i => {
        if (i.result === false) {
          ++ret;
        }
      });
      return ret;
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.requestData();
  },
  methods: {
    // on event
    onCollect() {
      const ques = this.questions[this.curQuesIndex];
      if (ques.collect_id) {
        this.$http
          .put(`/api/app/collect/cancel/${ques.collect_id}/3`)
          .then(() => {
            ques.collect_id = 0;
          });
      } else {
        this.$http
          .post("/api/app/collect", {
            ques_id: ques.ques_id,
            type: 3
          })
          .then(data => {
            ques.collect_id = data;
          });
      }
    },
    onCorrection() {
      this.showCorrectionDlg = true;
    },
    onDelete() {
      this.$confirm("确定要将本题移除错题吗？", "提示")
        .then(() => {
          this.$http
            .get(
              `/api/app/myExam/detach/${this.questions[this.curQuesIndex].id}`
            )
            .then(() => {
              this.$message.success("移除错题成功");
              this.questions.splice(this.curQuesIndex, 1);
            });
        })
        .catch(() => {});
    },
    onAnswer() {
      this.showAnswer = !this.showAnswer;
    },
    onPreQues() {
      if (this.curQuesIndex <= 0) {
        this.$message.warning("当前已是第一题");
        return;
      }
      this.curQuesIndex--;
      this.showAnswer = false;
    },
    onNextQues() {
      if (this.curQuesIndex >= this.questions.length - 1) {
        this.$message.warning("当前已是最后一题");
        return;
      }
      this.curQuesIndex++;
      this.showAnswer = false;
    },
    onCompleteQues() {
      const ques = this.questions[this.curQuesIndex];
      this.showAnswer = true;
      switch (ques.ques_type) {
        case 1: // 单选
        case 3: // 判断
          {
            if (!ques.single) {
              this.$warning("请选择答案", {
                duration: 1000
              });
              return;
            }
            let right;
            for (const i of ques.ques_option) {
              if (i.is_right == 1) {
                right = i.id;
                break;
              }
            }
            this.$set(ques, "result", right == ques.single);
          }
          break;
        case 2: // 多选
          {
            if (!ques.multi.length) {
              this.$warning("请选择答案", {
                duration: 1000
              });
              return;
            }
            // 找答案
            let right = [];
            ques.ques_option.forEach(i => {
              if (i.is_right == 1) {
                right.push(i.id);
              }
            });
            right.sort(function(a, b) {
              return a - b;
            });
            // 用户选择
            ques.multi.sort(function(a, b) {
              return a - b;
            });
            console.log("multi", right, ques.multi);
            this.$set(
              ques,
              "result",
              right.toString() == ques.multi.toString()
            );
          }
          break;
        case 4: // 连线
          {
            if (!ques.connection[0]) {
              this.$message.waring("请选择答案");
              return;
            }
            let hasWrong = false;
            for (let i = 0; i < ques.ques_option.left.length; ++i) {
              if (ques.ques_option.left[i].is_right != ques.connection[i]) {
                hasWrong = true;
                break;
              }
            }
            this.$set(ques, "result", !hasWrong);
          }
          break;
      }
    },
    onOver() {
      this.$confirm(
        this.questions.length - this.completeQuesCount > 0
          ? "还有题目未做完，是否结束巩固？"
          : "是否结束巩固？",
        "提示"
      )
        .then(() => {
          // 搜集作对题目的ID
          const correctQuesIDs = [];
          this.questions.forEach(i => {
            if (i.result) correctQuesIDs.push(i.id);
          });
          // 自动移除开关处理，打开开关 & 有作对的题目
          if (this.$route.query.auto && correctQuesIDs.length) {
            this.$http
              .get(`/api/app/myExam/detach/${correctQuesIDs.join()}`)
              .then(() => {
                this.$router.push({
                  name: "MemberErrorList"
                });
              });
          } else {
            this.$router.push({
              name: "MemberErrorList"
            });
          }
        })
        .catch(() => {});
    },
    // other
    getCurDisplay(type) {
      const ques = this.questions[this.curQuesIndex];
      let ret = "";
      switch (ques.ques_type) {
        case 1:
        case 3:
          if (type == "answer") {
            ques.ques_option.forEach(i => {
              if (i.is_right == 1) {
                ret = this.$pub.getLetterNumber(i.id);
              }
            });
          } else if (type == "choice" && ques.single) {
            ret = this.$pub.getLetterNumber(ques.single);
          }
          break;
        case 2:
          if (type == "answer") {
            ques.ques_option.forEach(i => {
              if (i.is_right == 1) {
                ret += this.$pub.getLetterNumber(i.id) + ",";
              }
            });
          } else if (type == "choice") {
            ques.multi.forEach(i => {
              ret += this.$pub.getLetterNumber(i) + ",";
            });
          }
          ret = ret.slice(0, -1);
          break;
        case 4:
          if (type == "answer") {
            ques.ques_option.left.forEach(i => {
              ret += this.$pub.getLetterNumber(i.is_right) + ",";
            });
          } else if (type == "choice") {
            ques.connection.forEach(i => {
              if (i) {
                ret += this.$pub.getLetterNumber(i) + ",";
              }
            });
          }
          ret = ret.slice(0, -1);
          break;
      }
      return ret;
    },
    // request
    requestData() {
      this.$http
        .get(`/api/app/myExam/errorRecord/${this.paperID}/${this.createdAt}`)
        .then(data => {
          this.questions = data;
          this.questions.forEach(i => {
            switch (i.ques_type) {
              case 1:
              case 3:
                this.$set(i, "single", 0);
                break;
              case 2:
                this.$set(i, "multi", []);
                break;
              case 4:
                this.$set(i, "connection", new Array(10));
                break;
            }
            if (i.point_name) {
              i.points = i.point_name.split(",");
            }
            if (!i.ques_analysis) {
              i.ques_analysis = "暂无解析";
            }
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.nav {
  height: 53px;
  background-color: #fff;
  margin-top: 30px;
  .item {
    line-height: 53px;
    margin-left: 20px;
  }
}
.content {
  margin: 30px 0 80px 0;
  display: flex;
  justify-content: space-between;
  .left {
    .question {
      width: 890px;
      border: 1px solid #e9ecec;
      border-bottom: 0;
      border-radius: 5px 5px 0 0;
      // min-height: 330px;
      box-sizing: border-box;
      background-color: #fff;
      padding: 20px 20px 50px 20px;
      position: relative;
      .stem {
        font-size: 18px;
        font-weight: 500;
        color: #333;
        display: flex;
        .num-type {
          white-space: nowrap;
          .type {
            color: $primary_color;
            margin-right: 8px;
          }
        }
      }
      .option-wrapper {
        position: relative;
        .result-tag {
          width: 32px;
          position: absolute;
          top: 0;
          left: -20px;
        }
        /deep/ .option-content {
          display: inline-block;
          /deep/ img {
            max-width: 400px;
          }
        }
        .options {
          display: flex;
          flex-direction: column;
          margin: 20px 0 0 30px;
          .option {
            margin-bottom: 15px;
            color: #333;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .btn-ok {
          text-align: center;
          padding-top: 30px;
        }
        .connection {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          .part-title {
            font-size: 18px;
            font-weight: 500;
            color: #333;
            margin-bottom: 20px;
          }
          .part-ques {
            margin-left: 30px;
            .ques {
              width: 353px;
              border: 1px solid #d9d9d9;
              border-radius: 5px;
              padding: 15px;
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0;
              }
              .stem {
                color: #333;
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 10px;
                .stem-number {
                  margin-right: 5px;
                }
                .stem-text {
                  display: inline-block;
                }
              }
              .answer {
                .tag {
                  margin-right: 10px;
                }
              }
            }
          }
          .part-option {
            .option {
              width: 353px;
              border: 1px solid #d9d9d9;
              border-radius: 5px;
              padding: 15px;
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0;
              }
              .option-number {
                display: inline-block;
                width: 65px;
                height: 25px;
                border: 1px solid $primary_color;
                color: $primary_color;
                border-radius: 3px;
                font-size: 16px;
                line-height: 25px;
                text-align: center;
                margin-right: 10px;
              }
            }
          }
        }
      }
      .watch-answer {
        margin-top: 20px;
        font-size: 16px;
        color: #666;
        .bar {
          height: 50px;
          line-height: 50px;
          background-color: #f7f7f7;
          padding: 0 20px;
          .right {
            margin-right: 50px;
          }
          .tag {
            color: $primary_color;
          }
        }
        .point {
          padding: 20px;
          .tag {
            display: inline-block;
            background-color: $primary_color;
            border-radius: 5px;
            color: #fff;
            padding: 0 10px;
            margin-right: 5px;
          }
          .empty-tag {
            color: #333;
          }
        }
        .analysis {
          padding: 0 20px;
          display: flex;
          .title {
            width: 90px;
          }
          .rich-text {
            color: #333;
          }
        }
      }
    }
    .bottom {
      height: 78px;
      width: 890px;
      line-height: 78px;
      box-sizing: border-box;
      background-color: #fff;
      border: 1px solid #e9ecec;
      border-bottom: 0;
      border-radius: 0 0 5px 5px;
      border-top: 1px solid #f4f4f4;
      display: flex;
      justify-content: space-around;
      font-size: 16px;
      color: #595959;
      p {
        cursor: pointer;
        * {
          vertical-align: middle;
        }
        img {
          width: 15px;
          height: 17px;
          margin-right: 5px;
        }
        .active {
          color: $primary_color;
        }
      }
    }
  }

  .right {
    width: 290px;
    .btn {
      height: 45px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .el-button {
        width: 137px;
      }
    }
    .board {
      background-color: #fff;
      border-radius: 5px;
      margin-bottom: 15px;
      .board-title {
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
        background-color: #f7f7f7;
        border-radius: 5px 5px 0 0;
        .tag {
          margin-right: 5px;
        }
        .current {
          font-size: 22px;
          color: #4169ff;
        }
      }
      .board-content {
        height: 250px;
        padding: 15px;
        box-sizing: border-box;
        overflow-y: auto;
        .item {
          display: inline-block;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 3px;
          border: 1px solid #e8e8e8;
          background-color: #fff;
          margin-right: 10px;
          margin-bottom: 13px;
          font-size: 14px;
          color: #b8b8b8;
          cursor: pointer;
          &:nth-of-type(7n) {
            margin-right: 0;
          }
        }
        .right {
          border-color: #00b174;
          background-color: #00b174;
          color: #fff;
        }
        .wrong {
          border-color: #ff5500;
          background-color: #ff5500;
          color: #fff;
        }
        .select {
          border-color: $primary_color;
          background-color: #fff;
          color: $primary_color;
        }
      }
    }
    .tip {
      height: 40px;
      background-color: #fff;
      border: 1px solid #e9ecec;
      border-radius: 5px;
      display: flex;
      justify-content: space-around;
      line-height: 40px;
      margin-bottom: 15px;
      p * {
        vertical-align: middle;
      }
      i {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 2px;
        margin-right: 5px;
      }
      .tip-right i {
        background-color: #00b174;
      }
      .tip-wrong i {
        background-color: #ff5500;
      }
      .tip-incomplete i {
        background-color: #fff;
        border: 1px solid #e8e8e8;
      }
    }
    .btn-over {
      width: 100%;
    }
  }
}
</style>
